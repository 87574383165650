<template>
  <div>
    <div id="myDiv">
      <div class="carousel-wrapper">
        <el-carousel height="400px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center">
                  <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>LESS THAN TRUCKLOAD</small><br></h1>
                  <p style="font-size:23px;font-weight:700;">Flexibility against complexity <br />LTL SERVICE</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

<div class="container-content" >
    <el-breadcrumb separator="/" style="margin-bottom: 60px;">
      <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
      <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
      <el-breadcrumb-item>Less Than Truckload</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display:flex;margin-bottom:60px;">
      <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
        <div class="son-my-div2">
          <div class="text-title">
            We are the LTL experts with comprehensive North American coverage and a broad portfolio of expedited and specialized LTL freight shipping services. At Union Worldwide, you can count on straightforward communication, shipment tracking and exceptiona LTL service levels when moving LTL shipments so you won't be left in the dark.
            </div>
        <div>
      </div>
    </div>
  </div>
</div>
</div>

<div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
          LTL SERVICES TAILORED TO YOUR BUSINESS
        </div>
        <span>How many hours do you spend comparing carriers and rates? Instead of managing freight by yourself, share the load with Union Worldwide. LTL shipping is ideal for businesses who have freight shipments less than 15,000 pounds. Union Worldwide offers real-time shipment tracking and equipment for all load sizes to help manage your business with confidence.</span>
      </div>
</div>

<div class="newblock" style="background: #DDDDDD;">
  <div class="newblock-content" style="color:black;">
    <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
      WHY USE UWL FOR YOUR LESS THAN TRUCKLOADSHIPMENTS?
    </div>
    <span>Union Worldwide’s technology and experts work hand-in-hand to offer customers competitive quotes, create BOLs, and walk through the entire shipping process from start to finish. We use multiple best practices to provide you with the best LTL freight rates. Our team of experienced freight experts work with you and offer assistance throughout the process.</span>
      <el-row style="margin-top:30px;">
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/LTLHighVolume_Icon.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">National And Regional access</div>
              <div style="height: 100px;">Direct access to our national and regional LTL carriers.</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/LTLDedicatedRep_Icon.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Dedicated Representatives & Proactive Communication</div>
              <div style="height: 100px;">A dedicated representative with proactive communication skills that guide you along the way.</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/LTLAudits_Icon.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Audits</div>
              <div style="height: 100px;">Audits to reduce your chance of a rebill due to inaccurate or insufficient information.</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/LTLExpertise_Icon.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Competitive Prices</div>
              <div style="height: 100px;">Leverage our high volume for competitive prices.</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/LTLReceiver_Icon.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Receiver</div>
              <div style="height: 100px;">Receiver location verification to make sure your shipping destination doesn't require any special considerations.</div>
            </div>
          </el-col>
        </el-row>
  </div>
</div>


<div class="newblock" style="background:#00131a">
  <div class="newblock-content">
    <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
      WHAT ARE THE 4 KEY BENEFITS TO SHIPPING LTL?
    </div>
    <span>Are you looking for the best LTL freight rates from top carriers? Look no further than Union Worldwide! With our easy-to-use platform, simply provide us with a few details about your shipment, and we’ll provide you with instant shipping quotes from the best freight carriers in one convenient place.</span>
      <el-row style="margin-top:30px;">
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/MoreOptions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Solution provider</div>
              <div style="height: 100px;">Receive solutions for your project freight</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/SaveMoney-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Better budgeting</div>
              <div style="height: 100px;">Pooling loads from multiple shippers means paying for only the space you use on a truck</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/Emissions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Various Service Options</div>
              <div style="height: 100px;">Pick from options like same or next-day LTL shipping, expedited delivery and guaranteed services </div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/MoreServiceOptions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Visibility And Connectivity</div>
              <div style="height: 100px;">Easily compare LTL shipping quotes from the nation’s best contract carriers</div>
            </div>
          </el-col>
        </el-row>
  </div>
</div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      carouseData: [
        { url: require("../../assets/son_img/LTT.png") }],
    };
  },
};
</script>

<style scoped>
.ziti1 {
  font-size: 25px;
  /* text-align: center; */
}
.ziti {
  font-size: 25px;
}
/* 图片 */
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}


.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1200px;
  color: white;
  padding:30px 20px;
}
.text-primary{
  font-weight: 700;
  font-size: 36px;
  color: #0093d0 !important;
  line-height: 36px;
}



.img-hold{
  width: 80px;
}
</style>
