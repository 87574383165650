<template>
<div>
<div id="myDiv">
    <div class="carousel-wrapper">
      <el-carousel height="400px" >
        <el-carousel-item v-for="item in carouseData" :key="item.index">
          <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
            <div class="container">
              <div class="text-block text-center">
                <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>INTERMODAL</small><br></h1>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
</div>
  <div class="container-content" >
    <el-breadcrumb separator="/" style="margin-bottom: 60px;">
      <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
      <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
      <el-breadcrumb-item>Intermodal</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display:flex;margin-bottom:60px;">
      <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
        <div class="son-my-div2">
          <div class="text-title">
            Lower costs and minimize your environmental impact with intermodal shipping, we help to strenthen your logisitics strategy
          </div>
          <br>
          <div style="text-indent: 2em;">Whether you are looking for a door to door offering, drayage and port services, or would like recommendations on how a multi-modal solution can improve your operations, the experts at Union Worldwide can create a customized solution to help you reach your goals. At Union Worldwide, you can rest assure that expert support designed to give you cost effective, reliable logistics solutions.</div>
          <br />
        </div>
      </div>
      <div style="width: 50%;display: flex;align-items: center;justify-content: center;">
        <div class="content-hold">
          <div class="introImages">
              <el-card>
                <div style="font-weight:700;font-size:25px;color:#0093d0 !important;margin-bottom:20px;">NEED HELP FROM AN EXPERT?  </div>
                <el-form :model="form" :rules="rules" ref="form">
                  <el-form-item label="First Name:" prop="firstname">
                    <el-input v-model="form.firstname"></el-input>
                  </el-form-item>
                  <el-form-item label="Email:" prop="email">
                    <el-input v-model="form.email"></el-input>
                  </el-form-item>
                  <el-form-item label="Let us know how we can help:" prop="help">
                    <el-input v-model="form.help" type="textarea"></el-input>
                  </el-form-item>
                  <el-button type="primary" @click="submitForm('form')">Confirm </el-button>
                </el-form>
              </el-card>
          </div>
	      </div>
      </div>
    </div>
  </div>

</div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      carouseData: [
        { url: require("../../assets/son_img/Intermodal.png") }],

      form: {
          firstname: '',
          email: '',
          help: ''
      },
      rules: {
          firstname: [
            { required: true, message: 'Please enter a value.', trigger: 'blur' },
          ],
          email:[
            { required: true, message: 'Please enter a value.', trigger: 'blur' },
            { type: 'email', message: 'Please enter a value.', trigger: ['blur', 'change'] }
          ],
          help:[
            { required: true, message: 'Please enter a value.', trigger: 'blur' },
          ]
        }
    }
    
  },
  methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$message.success("submit success!")
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      }
    }
};
</script>

<style scoped>
.ziti {
  font-size: 25px;
}
/* 图片 */
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}







.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

</style>
