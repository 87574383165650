<template>
  <div>
    <div id="myDiv">
      <div class="carousel-wrapper">
        <el-carousel height="400px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center">
                  <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>AIR</small><br></h1>
                  <p style="font-size:23px;font-weight:700;">Flexibility against complexibility</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>


    <div class="container-content" >
      <el-breadcrumb separator="/" style="margin-bottom: 60px;">
        <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
        <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
        <el-breadcrumb-item>Air Freight</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="display:flex;margin-bottom:60px;">
        <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
          <div class="son-my-div2">
            <div class="text-title">
              With our flexible service options as well as fast delivery  , we offer reliable and hassel-free air freight options.
            </div>
            <br>
            <div style="text-indent: 2em;">Options such as Economy, Standard or Expedited shipping are also available for your consideration!</div>
            <div style="text-indent: 2em;">We offer flexible and integrated delivery options from airport to airport, airport to door or door to door.</div>
          </div>
        </div>
        <!-- <div style="width: 50%;display: flex;align-items: center;justify-content: center;">
          <div class="content-hold">
            <div class="introImages">
                <div >
                    <img src="../../assets/image/SidebarContent.png" id="img1" alt="9.2 Satisfaction Score out of 10">
                </div>
            </div>
	      </div>
      </div> -->
      </div>
    </div>

    <div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:20px;margin-bottom:20px;">
          SERVICES OFFERED
        </div>
        <el-row style="font-size:22px;padding:20px;line-height:50px;">
          <el-col :span="12" style="text-align: left;">International imports and exports into U.S.
            <div style="text-indent: 2em;">•	3-5 day (premium)</div>
            <div style="text-indent: 2em;">•	5-7 day (standard)</div>
          </el-col>
          <el-col :span="12" style="text-align: left;">Next Flight Out (NFO)
            <div style="text-indent: 2em;">•	Consolidations</div>
            <div style="text-indent: 2em;">•	Door-to-door service options</div>
            <div style="text-indent: 2em;">•	White glove service</div>
          </el-col>
          <el-col :span="12" style="text-align: left;">Domestic
            <div style="text-indent: 2em;">•	Next day</div>
            <div style="text-indent: 2em;">•	 2nd day</div>
          </el-col>
        </el-row>
      </div>
    </div>

  <div class="newblock" style="background:#DDDDDD;">
  <div class="newblock-content"  style="color:black;">
    <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
      WHAT SETS US APART
    </div>
      <el-row style="margin-top:30px;">
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/MoreOptions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Global delivery and extensive air network</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/SaveMoney-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">High level of security</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/Emissions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Highly track-able and proactive cargo status tracking</div>
            </div>
          </el-col>
          <el-col :span="12" style="height: 150px;display:flex;">
            <div class="img-hold"><img src="../../assets/image/MoreServiceOptions-FTL.png" alt="Audits" style="width:80px;max-width: none;"></div>
            <div style="padding:0 20px;text-align: left;">
              <div style="font-size:25px;font-weight:700;margin-bottom: 5px;">Reliable customer service</div>
            </div>
          </el-col>
        </el-row>
  </div>
</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      carouseData: [
        { url: require("../../assets/son_img/Air.png") }],
    };
  },
};
</script>

<style scoped>
.ziti {
  font-size: 25px;
}
/* 图片 */
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}





.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1110px;
  color: white;
  padding:30px 20px;
}
.text-primary{
  font-weight: 700;
  font-size: 36px;
  color: #0093d0 !important;
  line-height: 36px;
}
</style>
