<template>
<div>
<div id="myDiv">
    <div class="carousel-wrapper">
      <el-carousel height="400px" >
        <el-carousel-item v-for="item in carouseData" :key="item.index">
          <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
            <div class="container">
              <div class="text-block text-center">
                <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>FULL  TRUCKLOAD</small><br></h1>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
</div>
  <div class="container-content" >
    <el-breadcrumb separator="/" >
      <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
      <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
      <el-breadcrumb-item>Full Truckload</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
  
  <div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
          FULL TRUCKLOAD SERVICES
        </div>
        <div>
          the most trusted name in nationwide FTL shipping services-we  offer a variety of equipment types to best suit your needs.
        </div>
        <el-row style="font-weight:700; font-size:22px;padding:20px;line-height:50px;">
          <el-col :span="12">Full Truckload/Dry Van/Flatbed</el-col>
          <el-col :span="12">Drayage/Port Services</el-col>
          <el-col :span="12">Dedicated fleet / Specialized Services</el-col>
          <el-col :span="12">Refrigerated/Temp-Controlled</el-col>
        </el-row>
      </div>

    </div>

      <div class="newblock2">
        <div class="newblock2-content">
          Union Worldwide’S UNIQUE MODEL CAN DELIVER ON  excellent, consistent service with the best teamwork and passion in the transportation industry
        </div>
      </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      carouseData: [
        { url: require("../../assets/son_img/Truckload.jpg") }],
      //员工对象
      form: {
        name: "",
        zhanghao: "",
        phone: "",
        status: "",
      },
    };
  },
};
</script>

<style scoped>
.ziti{
  font-size:25px;
}
/* 图片 */
img{  
  width: auto;  
  height: auto;  
  max-width: 100%;  
  max-height: 100%;     
} 
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}












.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}


.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}


.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 934px;
  color: white;
  padding:30px 20px;
}
.newblock2{
  background-color: black;
  width:100%;
  height: auto;
}
.newblock2-content{
  margin-left: auto;
  margin-right: auto;
  color:white;
  font-size: 27px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding:30px 20px;
  max-width: 934px;
  font-weight: 700;
}
</style>
