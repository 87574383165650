import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入了elem.js

import './plugins/element.js'
//引入axios请求拦截器
import setupaxios from './axiosutil/axios.js'

//配置路径
import axios from 'axios'
axios.defaults.baseURL = 'http://localhost:8081/uu/'
Vue.prototype.$http = axios

// 引入样式
import './assets/css/global.css'
Vue.config.productionTip = false

setupaxios(); // 添加axios请求拦截器


new Vue({
router,  
  store,
  render: h => h(App)
}).$mount('#app')
