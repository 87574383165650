<template>
  <router-view></router-view>
</template>

<script>
import axios from "axios";
export default {
  name: "app",
  data() {
    return {
      
    };
  },
  mounted () {

    

  },

  methods: {
    

    
  },
};
</script>

<style >

html, body {
  font-family: Arial, Helvetica, sans-serif;
}
</style>
