<template>
  <div>

    <div id="myDiv">
      <div class="carousel-wrapper">
        <el-carousel height="400px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center">
                  <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>Sea Freight</small><br></h1>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="container-content" >
      <el-breadcrumb separator="/" style="margin-bottom: 60px;">
        <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
        <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
        <el-breadcrumb-item>Sea Freight</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="display:flex;margin-bottom:60px;">
        <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
          <div class="son-my-div2">
            <div class="text-title">
              We offer the flexibility of routing &carrier options, competitive pricing, rate forecasting and volume-based bench marking. By partnering with major carriers, we can provide multiple selections of delivery plans. FCL  or LCL,  we handle every last detail of our customers’ particular logistics and forwarding needs.We  can flexibly deliver from port to port, port to door, or door to door, etc.
            </div>
          </div>
        </div>
        <!-- <div style="width: 50%;display: flex;align-items: center;justify-content: center;">
          <div class="content-hold">
            <div class="introImages">
                <div >
                    <img src="../../assets/image/SidebarContent.png" id="img1" alt="9.2 Satisfaction Score out of 10">
                </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
          SERVICES OFFERED
        </div>
        <el-row style="font-weight:700; font-size:20px;padding:20px;line-height:50px;text-align: left;">
          <el-col :span="12">•	Full Container Load (FCL)</el-col>
          <el-col :span="12">•	Less-than-Container Load (LCL)</el-col>
          <el-col :span="12">•	International imports and exports </el-col>
          <el-col :span="12">•	U.S. customs brokerage services and compliance consultancy</el-col>
          <el-col :span="12">•	Door-to-door/door-to-port</el-col>
          <el-col :span="12">•	Port-to-port/port-to-door</el-col>
        </el-row>
      </div>
    </div>
    


    <div class="newblock2">
      <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
      <div class="newblock2-content">

        <div style="font-size:20px;">
          Union Worldwide  helps you navigate ports and deliver on time. No matter where your freight is headed next, our 24/7/365 live, customer service and dedicated team of drayage experts bridge the gap between ocean and inland shipping.
        </div>
        <br>
        <div class="ziti" style="font-weight:800;font-size:20px;margin-bottom:15px;">
          Drayage service benefits:
        </div>
        <el-col style="text-indent: 2em;">•	Import/export/domestic capabilities </el-col>
        <el-col style="text-indent: 2em;">•	Direct communication checkpoints in and out of port</el-col>
        <el-col style="text-indent: 2em;">•	Warehousing and transloading contacts</el-col>
        <el-col style="text-indent: 2em;">•	Coverage for every U.S. and Canada port and rail ramp</el-col>
        <el-col style="text-indent: 2em;">•	Proactive tracking on and off the water</el-col>
        
      </div>
      </div>
    </div>


        <section class="community-panel text-center">
      <div style="display: flex;flex-direction: column;text-align: center;">
        <div class="heading-block" style="    display: flex;flex-direction: column;align-items: center;">
          <h2 class="text-primary">WHAT SETS US APART</h2>
        <div style="max-width:1460px;height: 250px;margin-bottom:40px;">
        <el-row >
          <el-col :span="6">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">ONE</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;   height: 200px;">Experienced team backed up by supply chain experts</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">TWO</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;    height: 200px;">Transparent pricing system</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">THREE</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;    height: 200px;">Seamless supply chain  with extensive overseas network</div>
            </div>
          </el-col>
          <el-col :span="6">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">FOUR</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;    height: 200px;">Full service covering drayage, intermodel, drop trailer and transloading</div>
            </div>
          </el-col>
        </el-row>
        </div>
        </div>
      </div>
    </section>

    




  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],

    carouseData: [
        { url: require("../../assets/son_img/Ocean.png") }],
    };
  },
};
</script>

<style scoped>
.ziti {
  font-size: 25px;
}
/* 图片 */
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}




.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1318px;
  color: white;
  padding:30px 20px;
}
.text-primary{
  font-weight: 700;
  font-size: 36px;
  color: #0093d0 !important;
  line-height: 36px;
}
.newblock2{
  background-color: #f7f7f7;
  width:100%;
  height: auto;
  padding: 0px 60px;
}

.newblock2-content{
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 1110px;
  color: black;
  padding:30px 20px;
}

</style>
