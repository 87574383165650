<template>
  <div>
    <div id="myDiv">
      <div class="carousel-wrapper">
        <el-carousel height="400px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center">
                  <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>DRAYAGE</small><br></h1>
                  <p style="font-size:23px;font-weight:700;">Reach your destination 100% sure and safe</p>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="container-content" >
      <el-breadcrumb separator="/" style="margin-bottom: 60px;">
        <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
        <el-breadcrumb-item><el-link type="primary" href="/">LOGISTICS SERVICES</el-link></el-breadcrumb-item>
        <el-breadcrumb-item>Drayage</el-breadcrumb-item>
      </el-breadcrumb>
      <div style="display:flex;margin-bottom:60px;">
        <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
          <div class="son-my-div2">
            <div class="text-title">
              In the dynamic world of international supply chains, companies need a partner who is agile, flexible, experienced, and knowledgeable.
            </div>
            <br>
            <div style="text-indent: 2em;">Our drayage experts coordinate every move for you - from appointments to process control to invoice auditing - to ensure hassle-free convenience and world-class customer service</div>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
          SERVICES OFFERED
        </div>
        <el-row style="font-weight:700; font-size:22px;padding:20px;line-height:50px;">
          <el-col :span="12">• Port drayage</el-col>
          <el-col :span="12">• Rail drayage</el-col>
          <el-col :span="12">• Import/export/domestic</el-col>
          <el-col :span="12">• Overweight & OOG</el-col>
          <el-col :span="12">• breakbulk</el-col>
          <el-col :span="12">• ISO tanks</el-col>
          <el-col :span="12">• Open top  & Flat rack</el-col>
          <el-col :span="12">• Transloading</el-col>
          <el-col :span="12">• Warehousing</el-col>
          <el-col :span="12">• Multi-stop</el-col>
          <el-col :span="12">• Port pricing</el-col>
        </el-row>
      </div>
    </div>

    <!-- <section class="community-panel text-center">
      <div style="display: flex;flex-direction: column;text-align: center;">
        <div class="heading-block">
          <h2 class="text-primary">2022 DRAYAGE LOAD MAP</h2>

          <h2 class="text-primary"><span style="font-size:48px;">221,174</span></h2>
        </div>

        <h2 class="text-uppercase"><img alt="Drayage Map" class="modal-image" src="../../assets/image/2022DrayageWEB_1.jpg" style="width: 700px;"><br>
        <span style="font-size:12px;">Click the Image to Zoom</span></h2>

      </div>
    </section> -->

    <section class="community-panel text-center">
      <div style="display: flex;flex-direction: column;text-align: center;">
        <div class="heading-block" style="    display: flex;flex-direction: column;align-items: center;">
          <h2 class="text-primary">WHAT SETS US APART</h2>
        <div style="max-width:1460px;height: 250px;margin-bottom:40px;">
        <el-row >
          <el-col :span="8">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">Flexible</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;   height: 200px;">Increase your flexibility and reduce costs by using Union Worldwide’s 24/7 drayage service, allowing you to move freight during off-peak hours.</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">BACKED BY EXPERTS</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;    height: 200px;">Experts dedicated to providing supply chain efficiencies with your best interest in mind. Customers value our commitment to ensure process control and to audit invoices.</div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="padding:0 20px;">
              <div style="background-color: #2A385F;color: #FFF;text-align: center;padding:10px 0">Expansive</div>
              <div style="background-image: linear-gradient(#e7e8e8, #ffffff);padding: 20px;text-align: left;    height: 200px;">Nationwide partner carriers allow Union Worldwide to service every port of entry in North America, as well as the IPI rail heads.</div>
            </div>
          </el-col>
        </el-row>
        </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      carouseData: [
        { url: require("../../assets/son_img/Drayage.png") }],
    };
  },
};
</script>

<style scoped>
.ziti {
  font-size: 25px;
}
/* 图片 */
img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}





.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 934px;
  color: white;
  padding:30px 20px;
}
.text-primary{
  font-weight: 700;
  font-size: 36px;
  color: #0093d0 !important;
  line-height: 36px;
}
</style>
