import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../components/Login.vue'
import home from '../components/home.vue'
import welcome from '../components/welcome.vue'
import setion1 from '../components/menu/setion1.vue'
import setion2 from '../components/menu/setion2.vue'
import setion3 from '../components/menu/setion3.vue'
import setion4 from '../components/menu/setion4.vue'
import setion5 from '../components/menu/setion5.vue'
import setion6 from '../components/menu/setion6.vue'


// import service1 from '../components/menu2/service1.vue'
import service2 from '../components/menu2/service2.vue'
// import service3 from '../components/menu2/service3.vue'
import service4 from '../components/menu2/service4.vue'
import service5 from '../components/menu2/service5.vue'
// import service6 from '../components/menu2/service6.vue'
// import service7 from '../components/menu2/service7.vue'
import service8 from '../components/menu2/service8.vue'
// import service9 from '../components/menu2/service9.vue'

import about_us from '../components/about_us.vue'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/home' },//配置初始访问都是在login里面 重定向
  // { path: '/Login', component: Login },
  { path: '/home', component: home, redirect: '/welcome',
   children:[
    { path: '/welcome', component: welcome },
    { path: '/FullTruckload', component: setion1 },
    { path: '/LTT', component: setion2 },
    { path: '/Intermodal', component: setion3 },
    { path: '/Drayage', component: setion4 },
    { path: '/AirFreight', component: setion5 },
    { path: '/SeaFreight', component: setion6 },

    { path: '/OOCargo', component: service2 },
    { path: '/WareHousing', component: service4 },
    { path: '/Customs', component: service5 },
    { path: '/LastMile', component: service8 },
    { path: '/ABOUT_US', component: about_us },

  ]}
]

const router = new VueRouter({
  routes
})

// //挂载路由导航守卫
// router.beforeEach((to , from , next)=>{
//   //如果访问的是登录页面
//   if(to.path == '/Login'){
//     return next();
//   }
//   //接下来判断是否存在token
//   const tokenStr = window.sessionStorage.getItem('token')
//   //如果token不存在
//   if(!tokenStr){
//     return next('/Login')
//   }
//   //都没问题，直接放行
//   next();
// })
export default router
