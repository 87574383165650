<template>
<div>
  <div id="myDiv">
      <div class="carousel-wrapper">
        <el-carousel height="400px" >
          <el-carousel-item v-for="item in carouseData" :key="item.index">
            <div class="carousel-item" :style="{ backgroundImage: `url(${item.url})` }">
              <div class="container">
                <div class="text-block text-center">
                  <h1 class="h2 text-uppercase" style="font-size: 55px;line-height: 58px;"><small>LAST MILE</small><br></h1>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>

    <div class="container-content" >
    <el-breadcrumb separator="/" style="margin-bottom: 60px;">
      <el-breadcrumb-item :to="{ path: '/' }"><el-link type="primary">HOME</el-link></el-breadcrumb-item>
      <el-breadcrumb-item><el-link type="primary" href="/">SPECIALIZED SERVICES</el-link></el-breadcrumb-item>
      <el-breadcrumb-item>Last Mile</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="display:flex;margin-bottom:60px;">
      <div class="container-textarea" style="width: 50%;margin: 0 30px;line-height:1.8">
        <div class="son-my-div2">
          <div class="text-title">
            Scalable & Customizable Last Mile Management
          </div>
          <br>
          <span>Union Worldwide’s last-mile solutions ensures quality performance & exceptional customer service. We offer customized last-mile services as a solution to complex shipping challenges.At Union Worldwide, our professional delivery capability, combining our 24/7/365 support, ensures efficient, fast and accurate logistics delivery services for the last mile.<br></span>
          <br>
        </div>
      </div>
    </div>
</div>

    <div class="newblock">
      <div class="newblock-content">
        <div class="ziti" style="font-weight:800;font-size:30px;margin-bottom:20px;">
          LAST MILE SERVICE OPTIONS
        </div>
         <el-row style="font-weight:700; font-size:20px;padding:20px;line-height:50px;text-align: left;">
          <el-col :span="12">•	Same-day delivery</el-col>
          <el-col :span="12">•	First & last mile</el-col>
          <el-col :span="12">•	Full load/unload</el-col>
          <el-col :span="12">•	etc.</el-col>
        </el-row>
      </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      drawer: false, //打开添加表格
      input: "", //查询
      tableData: [],
      //员工对象
    carouseData: [
        { url: require("../../assets/son_img_2/Final_Mile.png") }],
    };
  },
};
</script>

<style scoped>
.ziti{
  font-size:25px;
}
/* 图片 */
img{  
  width: auto;  
  height: auto;     
  max-width: 100%;  
  max-height: 100%;     
} 
.container {
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.son-my-div {
  margin: 0px;
  width: 500px;
  height: 300px;
  background-color: #f0f0f0;
}
.son-my-div2 {
  width: 100%;
}
.carousel-item{
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}
.container{
    max-width: 1680px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    height: 100%;
    align-items: center;
    color: white;
    justify-content: left;
}

.container-content{
  padding:20px 60px;
}

.container-textarea{
  width: 100%;
}

.text-title{
  color:#409EFF;
  font-size: 23px;
}

.small-title{
  font-weight: 700;
}

.newblock{
  background-color: #0093d0;
  width:100%;
  height: auto;
}
.newblock-content{
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 1110px;
  color: white;
  padding:30px 20px;
}
.text-primary{
  font-weight: 700;
  font-size: 36px;
  color: #0093d0 !important;
  line-height: 36px;
}
.newblock2{
  background-color: #f7f7f7;
  width:100%;
  height: auto;
  padding: 0px 60px;
}

.newblock2-content{
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  flex-direction: column;
  text-align: left;
  max-width: 1110px;
  color: black;
  padding:30px 20px;
}
</style>
