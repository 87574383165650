import axios from 'axios';



export default function setup() {
  //配置拦截器
  axios.interceptors.request.use(
    (config) => {
      // 在发送请求之前为请求头挂上token
      config
      .headers.Authorization = window.sessionStorage.getItem('token');
      console.log(config);
      // 可以在请求头中添加token等信息
      return config;
    },
    (error) => {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );
}